<template>
  <div fluid="none">
    <b-card v-if="projectList.length">
      <div class="mb-4 d-flex justify-content-between">
        <h5 class="font-weight-bold">Recent Project</h5>
        <b-link @click="openProjectList()">See More</b-link>
      </div>

      <div class="pl-2" v-for="project in projectList" :key="project.id">
        <b-row class="d-flex mt-2 list py-3" @click="goToDetailProject(project.id, project.code)">
          <div class="mx-3">
            <fa-icon icon="file-alt" style="width: 25px; height: 25px" />
          </div>
          <div class="field-data mr-2" cols="5">
            <div class="font-weight-bold project mb-1">{{ project.projectName }}</div>
            <div class="text-muted title mb-1">Project Date</div>
            <b-badge class="font-weight-bold date mb-2">
              {{ project.date }}
            </b-badge>
          </div>
          <div style="width: 80px" class="d-flex justify-content-end">
            <b-badge
              v-if="project.status === 'Proposal Submitted'"
              class="font-weight-normal status-proposal"
            >
              {{ project.status }}
            </b-badge>
            <b-badge
              v-else-if="project.status === 'Preparation'"
              class="font-weight-normal status-preparation"
            >
              {{ project.status }}
            </b-badge>
            <b-badge
              v-else-if="project.status === 'In Progress'"
              class="font-weight-normal status-in-progress"
            >
              {{ project.status }}
            </b-badge>
            <b-badge
              v-else-if="project.status === 'Maintenance'"
              class="font-weight-normal status-maintenance"
            >
              {{ project.status }}
            </b-badge>
            <b-badge
              v-else-if="project.status === 'Done'"
              class="font-weight-normal status-done"
            >
              {{ project.status }}
            </b-badge>
            <b-badge
              v-else-if="project.status === 'Cancelled'"
              class="font-weight-normal status-cancelled"
            >
              {{ project.status }}
            </b-badge>
            <b-badge v-else class="font-weight-normal status">
              {{ project.status }}
            </b-badge>
          </div>
        </b-row>
        <hr class="hr" />
      </div>
    </b-card>
    <b-card v-else>
      <div class="mb-4 d-flex justify-content-between">
        <h5 class="font-weight-bold">Recent Project</h5>
      </div>
      <div class="text-center mt-2">There is no project yet</div>
    </b-card>
  </div>
</template>

<script>

export default {
  created() {
    try {
      this.$projectHub.receiveMessage()
      this.$projectHub.$on('receive-all', this.onLoadProject)
      this.$projectHub.$on('receive-push', this.onNotifProject)
    } catch (error) {
      console.log('project : ' + error)
    }
  },
  beforeDestroy() {
    this.$projectHub.$off('receive-all', this.onLoadProject)
    this.$projectHub.$off('receive-push', this.onNotifProject)
  },
  data: () => ({
    projectList: []
  }),
  methods: {
    openProjectList() {
      this.$router.push({ name: 'Project List' })
    },
    onNotifProject(data) {
      this.projectList.pop()
      this.projectList.unshift(data)
    },
    onLoadProject(data) {
      this.projectList = data
    },
    goToDetailProject(id, code) {
      this.$router.push({
        path: 'view-project',
        query: {
          id: id,
          code: code
        }
      })
    }
  }
}
</script>

<style scoped>
.date {
  background-color: #f3f3f3;
  color: #a4a4a4;
  font-size: 14px;
}

.status-maintenance {
  width: fit-content;
  height: 22px;
  color: #a4a4a4;
  background-color: rgba(204, 204, 204, 0.2);
  font-size: 12px;
}

.status {
  width: fit-content;
  height: 22px;
  background-color: #f3f3f3;
  color: #a4a4a4;
  font-size: 12px;
}

.status-proposal {
  width: fit-content;
  height: 22px;
  color: rgba(76, 175, 80, 1);
  background-color: rgba(76, 175, 80, 0.2);
  font-size: 12px;
}

.status-preparation {
  width: fit-content;
  height: 22px;
  color: rgba(249, 191, 89, 1);
  background-color: rgba(83, 130, 251, 0.2);
  font-size: 12px;
}

.status-done {
  width: fit-content;
  height: 22px;
  color: rgba(219, 17, 17, 1);
  background-color: rgba(219, 17, 17, 0.2);
  font-size: 12px;
}

.status-in-progress {
  width: fit-content;
  height: 22px;
  color: #4caf50;
  background-color: rgba(90, 76, 175, 0.2);
  font-size: 12px;
}

.title {
  font-size: 12px;
}

.project {
  font-size: 20px;
  line-height: 24px;
}

.field-data {
  width: 175px;
}

.list:hover {
  background-color: #F6F6F6;
}

@media only screen and (max-width: 720px) {
  .field-data {
    width: 200px;
  }
}
</style>
