<template>
  <div>
    <b-card
      v-if="invoiceList.length"
    >
      <div class="mb-4 d-flex justify-content-between">
        <h5 class="font-weight-bold">Invoice List</h5>
        <b-link @click="openInvoiceList()">See More</b-link>
      </div>

      <div class="mb-2" v-for="invoice in invoiceList" :key="invoice.invoiceNumber">
        <div class="d-flex justify-content-between">
          <div class="font-weight-bold project">{{ invoice.projectName }}</div>
          <b-badge class="font-weight-normal status" variant="warning"
            >{{ invoice.status }}</b-badge
          >
        </div>
        <div class="text-muted title mb-1">Invoice No.</div>
        <div class="font-weight-bold mb-2">{{ invoice.invoiceNumber }}</div>
        <div class="text-muted title mb-1">Invoice Date</div>
        <b-badge class="font-weight-bold date mb-2">
          {{ invoice.invoiceDate }}
        </b-badge>
        <hr class="hr" />
      </div>
    </b-card>
    <b-card
      v-else
    >
      <div class="mb-4 d-flex justify-content-between">
        <h5 class="font-weight-bold">Invoice List</h5>
      </div>
      <div class="text-center mt-2">There is no invoice data yet</div>
    </b-card>
  </div>
</template>

<script>

export default {
  created() {
    try {
      this.$invoiceHub.receiveMessage()
      this.$invoiceHub.$on('receive-all', this.onLoadInvoice)
      this.$invoiceHub.$on('receive-push', this.onNotifInvoice)
    } catch (error) {
      console.log('invoice : ' + error)
    }
  },
  beforeDestroy() {
    this.$invoiceHub.$off('receive-all', this.onLoadInvoice)
    this.$invoiceHub.$off('receive-push', this.onNotifInvoice)
  },
  data: () => ({
    invoiceList: []
  }),
  methods: {
    openInvoiceList() {
      this.$router.push({ name: 'Invoice List' })
    },
    onNotifInvoice(data) {
      this.invoiceList.pop()
      this.invoiceList.unshift(data)
    },
    onLoadInvoice(data) {
      this.invoiceList = data
    }
  }
}
</script>

<style scoped>
.project {
  font-size: 20px;
  line-height: 24px;
}
.title {
  font-size: 12px;
}
.status {
  width: 64px;
  height: 22px;
  color: white;
  font-size: 12px;
}
.date {
  background-color: #f3f3f3;
  color: #a4a4a4;
  font-size: 14px;
}
</style>
