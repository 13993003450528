<template>
  <div>
    <b-card
      v-if="notificationList.length"
    >
      <div class="mb-4 d-flex justify-content-between">
        <h5 class="font-weight-bold">Recent Notifications</h5>
        <b-link @click="openNotificationsPage">See More</b-link>
      </div>

      <div class="pl-4 pr-4">
        <div v-for="notification in notificationList" :key="notification.id">
          <div class="pt-3">
            <b-row>
              <b-avatar
                badge
                badge-left
                badge-top
                badge-offset="-10px"
                class="mr-3"
                badge-variant="danger"
                size="md"
                src="https://placekitten.com/300/300"
              ></b-avatar>
              <div class="ml-1">
                <div class="info">
                  <span class="font-weight-bold">{{ notification.employeeName }}</span>
                  <span class="text-muted"> add new project </span>
                  <span class="font-weight-bold">{{
                    notification.projectName
                  }}</span>
                  <br>
                  <span class="message-notification">{{
                    notification.message
                    }}</span>
                </div>
              </div>
            </b-row>
            <b-row>
              <b-col class="ml-5 text-muted">{{ moment(notification.createdDate).format('YYYY-MM-DD HH:mm:ss') }}</b-col>
            </b-row>
            <hr class="hr" />
          </div>
        </div>
      </div>
    </b-card>
    <b-card
      v-else
    >
      <div class="mb-4 d-flex justify-content-between">
        <h5 class="font-weight-bold">Recent Notifications</h5>
      </div>
      <div class="text-center mt-2">There is no notifications yet</div>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data: () => ({
    notificationList: []
  }),
  created() {
    try {
      this.$notificationHub.receiveMessage()
      this.$notificationHub.$on('receive-all', this.onLoadNotification)
      this.$notificationHub.$on('receive-push', this.onNotification)
    } catch (error) {
      console.log('notification : ' + error)
    }
  },
  beforeDestroy() {
    this.$notificationHub.$off('receive-all', this.onLoadNotification)
    this.$notificationHub.$off('receive-push', this.onNotification)
  },
  methods: {
    moment,
    openNotificationsPage() {
      this.$router.push({ name: 'Notifications' })
    },
    onNotification(data) {
      this.notificationList.pop()
      this.notificationList.unshift(data)
    },
    onLoadNotification(data) {
      this.notificationList = data
    }
  }
}
</script>
<style scoped>
.info {
  width: 190px;
  max-width: 220px;
  margin-top: -5px;
  text-align: start;
  font-size: 14px;
  line-height: 150%;
}
.time {
  width: auto;
  text-align: center;
}

.message-notification {
  font-size: 12px;
}
</style>
