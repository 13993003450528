<template>
  <div>
    <b-row>
      <b-col
        v-for="summary in summaryListTop"
        :key="summary.status"
      >
        <b-card>
          <b-card-title class="font-weight-bold number">{{ summary.count !== null ? summary.count : 'N/A' }}</b-card-title>
          <b-card-text class="font-weight-bold title">{{ summary.status ? summary.status : '-' }}</b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="summary in summaryListBot"
        :key="summary.status"
      >
        <b-card>
          <b-card-title class="font-weight-bold number">{{ summary.count !== null ? summary.count : 'N/A' }}</b-card-title>
          <b-card-text class="font-weight-bold title">{{ summary.status ? summary.status : '-' }}</b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <recent-project />
      </b-col>
      <b-col>
        <invoice-list />
      </b-col>
      <b-col>
        <recent-notifications />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '@/api'
import RecentProject from './RecentProject.vue'
import InvoiceList from './InvoiceList.vue'
import RecentNotifications from './RecentNotifications.vue'

export default {
  components: { RecentProject, RecentNotifications, InvoiceList },
  data: () => ({
    summaryListTop: [],
    summaryListBot: []
  }),
  created() {
    this.fetchProject()
  },
  methods: {
    async fetchProject() {
      const { data } = await api.dashboard.listProject()
      this.summaryList = data.data
      for (let i = 0; i < data.data.length; i++) {
        if (i < 4) {
          this.summaryListTop.push(data.data[i])
        } else {
          this.summaryListBot.push(data.data[i])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.number {
  font-size: 40px;
}
.title {
  font-size: 14px;
}
</style>
